import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
// import JSZip from 'jszip';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	uploadContainer: {
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
	progress: {
		width: '100%',
		marginTop: theme.spacing(2),
	},
	resultContainer: {
		marginTop: theme.spacing(4),
	},
}));

const Agenda1 = () => {
	const classes = useStyles();
	const [excelFile, setExcelFile] = useState(null);
	const [zipFile, setZipFile] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [progress, setProgress] = useState(0);
	const [result, setResult] = useState(null);

	const handleExcelChange = (event) => {
		setExcelFile(event.target.files[0]);
	};

	const handleZipChange = (event) => {
		setZipFile(event.target.files[0]);
	};

	const processFiles = async () => {
		if (!excelFile || !zipFile) {
			alert('Por favor, selecione ambos os arquivos Excel e ZIP.');
			return;
		}

		setProcessing(true);
		setProgress(0);

		// Process ZIP file
		// const zip = new JSZip();
		// const zipContent = await zip.loadAsync(zipFile);
		// const xmlFiles = [];

		// zip.forEach((relativePath, zipEntry) => {
		// 	if (relativePath.endsWith('.xml')) {
		// 		xmlFiles.push(zipEntry.async('string'));
		// 	}
		// });

		// const xmlContents = await Promise.all(xmlFiles);
		// setProgress(50);

		// Process Excel file
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: 'array' });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const excelData = XLSX.utils.sheet_to_json(worksheet);
			console.log(excelData); // Process Excel data as needed

			// Simulate processing completion
			setTimeout(() => {
				setProgress(100);
				setProcessing(false);
				setResult('Processamento concluído com sucesso!');
			}, 2000);
		};
		reader.readAsArrayBuffer(excelFile);
	};

	return (
		<div className={classes.container}>
			<div className={classes.uploadContainer}>
				<input type="file" accept=".xlsx,.xls" onChange={handleExcelChange} />
				<input type="file" accept=".zip" onChange={handleZipChange} />
			</div>
			<Button
				variant="contained"
				color="primary"
				onClick={processFiles}
				disabled={processing}
			>
				Iniciar Processamento!
			</Button>
			{processing && <LinearProgress className={classes.progress} variant="determinate" value={progress} />}
			{result && (
				<div className={classes.resultContainer}>
					<h2>Resultado:</h2>
					<p>{result}</p>
				</div>
			)}
			<div className={classes.root}>

			</div>
		</div>
	);
};

export default Agenda1;
