import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import openSocket from "socket.io-client";

const useStyles = makeStyles(theme => ({
  container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
}));

const Agenda1= () => {
	const classes = useStyles();

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
		return () => {
			socket.disconnect();
		};
	}, []);

	return(
		<div className={classes.root}>
			<iframe title="Calendário" src='https://calendar.google.com/calendar/embed?src=usdp9dso4c8mhtcp24s6mo12i0%40group.calendar.google.com&ctz=America%2FSao_Paulo' height='800' width='100%' frameBorder='0'/></div>
	)
	}
	

export default Agenda1;